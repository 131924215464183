import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Temporal } from "@js-temporal/polyfill";
import Tippy from "@tippyjs/react";
import classNames from "classnames";
import { kebabCase } from "lodash";
import { createRef, useEffect, useLayoutEffect, useRef, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Node } from "slate";
import { keyframes } from "styled-components";
import tw, { css, styled } from "twin.macro";

import {
  flow_text_key_enum,
  FlowOfferFragment,
  FlowOfferGroupFragment,
  FlowSubscriptionFragment,
  language_enum,
  PauseReason,
  subscriber_flow_status_enum,
} from "../../../__generated__/graphql";
import poweredByImg from "../../../assets/powered-by-prosperstack.png";
import Editor from "../../../common/editor2/Editor";
import Spinner from "../../../common/Spinner";
import EditableFlowText from "../../flow/edit/EditableFlowText";
import UpsellBanner, { BannerMode } from "../../upgrade-account/UpsellBanner";
import DefaultStyles from "./DefaultStyles";
import { FlowDisplayMode, FlowError, FlowStep, FlowText } from "./lib/types";
import {
  screenLarge,
  screenMobile,
  slideBackDistance,
  slideDistance,
  transitionDuration,
} from "./lib/variables";
import OfferGroupModal from "./OfferGroupModal";
import OfferModal from "./OfferModal";
import CloseXButton from "./ui/CloseXButton";
import FlowButton from "./ui/FlowButton";
import FlowErrorAlert from "./ui/FlowErrorAlert";
import Pagination from "./ui/Pagination";

interface FlowContentProps {
  testMode: boolean;
  previewMode: boolean;
  editorMode: boolean;
  currentStepIndex: number;
  steps: FlowStep[];
  totalSteps: number;
  currentStep: FlowStep | undefined;
  offer: FlowOfferFragment | null;
  nextOrderDate?: Temporal.PlainDate;
  pauseReasons?: PauseReason[];
  offerGroup: FlowOfferGroupFragment | null;
  swappableProducts: FlowSubscriptionFragment | null;
  onAcceptOffer: (selectedOptionIndex: number) => void;
  onAcceptGroupOffer: (selectedOptionIndex: number, offerId: string) => void;
  onDeclineOffer: () => void;
  onDeclineOfferGroup: () => void;
  onAbort: () => void;
  loading: boolean;
  stepElement: JSX.Element | null;
  nextButtonEnabled: boolean;
  isFinalButton: boolean;
  isFinalOfferButton: boolean;
  onClickNext: () => void;
  submitting: boolean;
  onSetStep: (id: string) => void;
  pagingDisabled: boolean;
  companyName: string;
  logoUrl: string | undefined;
  showBranding: boolean;
  globalCssValue: string;
  cssValue: string;
  subscriberSegmentMatches?: any[]; // TODO: FlowQuery_flowByToken_flow_subscriber_matching_segments[];
  flowText: FlowText;
  onUpdateText: (
    key: flow_text_key_enum,
    value: Partial<Record<language_enum, Node[]>>
  ) => void;
  offerModalIsOpen: boolean;
  offerGroupModalIsOpen: boolean;
  status?: subscriber_flow_status_enum;
  presentedOfferStepIds: string[];
  displayMode: FlowDisplayMode;
  isFreeMode: boolean;
  onClickUpgrade?: (modalIsOpen: boolean) => void;
  acceptOfferError?: FlowError;
  cancelError?: FlowError;
  eligibilityMessage?: string;
  eligibilityMessageHeader?: string;
}

const Layout = styled.div`
  ${tw`flex flex-col flex-nowrap`}
  height: 100%;
`;

const ContentContainer = styled.div<{
  isEditMode: boolean;
  displayMode: FlowDisplayMode;
}>`
  ${tw`flex items-stretch relative z-0`}
  flex-grow: 100;

  ${(props) =>
    !props.isEditMode &&
    css`
      @media (max-width: ${screenMobile}) {
        ${tw`flex-col items-start`}
      }
    `}

  ${(props) =>
    !props.isEditMode &&
    props.displayMode === "modal" &&
    css`
      && {
        ${tw`flex-col items-start`}
      }
    `}
`;

const SidebarFull = styled.div<{
  isEditMode: boolean;
  show: boolean;
  displayMode: FlowDisplayMode;
}>`
  ${tw`flex-grow flex-shrink-0 relative`}
  width: 18rem;
  z-index: 1;

  transition: left 0.2s;
  left: ${(props) => (props.show ? "0" : "-18rem")};
  position: ${(props) => (props.show ? "relative" : "absolute")};

  ${(props) =>
    !props.isEditMode &&
    props.show &&
    css`
      @media (max-width: ${screenLarge}) {
        width: 10rem;
      }

      @media (max-width: ${screenMobile}) {
        ${tw`w-full border-b`}
        border-color: transparent; // IE11
        border-color: var(--flow-divider-color);
      }
    `}

  ${(props) =>
    !props.isEditMode &&
    props.displayMode === "modal" &&
    css`
      && {
        ${tw`w-full border-b`}
        border-color: transparent; // IE11
        border-color: var(--flow-divider-color);
      }
    `}
`;

const SidebarAbsolute = styled.div<{
  isEditMode: boolean;
  displayMode: FlowDisplayMode;
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: -1px;
  bottom: 0;
  overflow: hidden;

  ${(props) =>
    !props.isEditMode &&
    css`
      @media (max-width: ${screenMobile}) {
        ${tw`static`}

        &:after {
          display: none;
        }
      }
    `}

  ${(props) =>
    !props.isEditMode &&
    props.displayMode === "modal" &&
    css`
      ${tw`static`}

      &:after {
        display: none;
      }
    `}

  &:after {
    content: "";
    width: 1px;
    height: calc(100% - 10rem);
    position: absolute;
    top: 5rem;
    right: 0;
    background: var(--flow-divider-color);
  }
`;

const SidebarContent = styled.div<{
  isEditMode: boolean;
  displayMode: FlowDisplayMode;
}>`
  ${tw`relative py-20 ml-auto h-full pr-20`}
  width: 18rem;

  ${(props) =>
    !props.isEditMode &&
    css`
      @media (max-width: ${screenLarge}) {
        ${tw`px-5 w-auto`}
        max-width: 16rem;
      }

      @media (max-width: ${screenMobile}) {
        ${tw`relative pt-0 pb-2 px-5 m-0 w-full mx-auto`}
        max-width: 38rem;
      }
    `}

  ${(props) =>
    !props.isEditMode &&
    props.displayMode === "modal" &&
    css`
      && {
        ${tw`relative pt-2 pb-2 px-5 m-0 w-full mr-auto`}
        max-width: 38rem;
      }
    `}
`;

const PoweredBy = styled.div`
  position: absolute;
  bottom: 17px;
  left: 20px;
  width: 160px;
  height: 16px;

  @media (max-width: ${screenMobile}) {
    display: none;
  }

  @media (max-height: 400px) {
    display: none;
  }
`;

const SegmentMatches = tw.div`flex flex-wrap -m-1 mt-5`;

const SegmentBox = tw.div`text-sm font-semibold px-2 rounded-md text-white m-1 bg-purple-600`;

const slideOut = keyframes`
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(${slideBackDistance}rem, 0);
  }
  100% {
    transform: translate(${-1 * slideDistance}rem, 0);
  }
`;

const slideIn = keyframes`
  0% {
    transform: translate(${slideDistance}rem, 0);
  }
  10% {
    transform: translate(${slideDistance + slideBackDistance}rem, 0);
  }
  100% {
    transform: translate(0, 0);
  }
`;

const StyledCSSTransition = styled(CSSTransition)<{ firstStepIn: boolean }>`
  &.step-enter {
    opacity: 0;

    ${(props) =>
      !props.firstStepIn &&
      css`
        animation: ${slideIn} ${transitionDuration}ms ease-in-out 1;
      `}

    & > div:before {
      display: none;
    }
  }

  &.step-enter-active {
    opacity: 1;

    ${(props) =>
      !props.firstStepIn &&
      css`
        transition: opacity ${transitionDuration / 2}ms ease-in;
      `}
  }

  &.step-enter-done {
    opacity: 1;
  }

  &.step-exit {
    opacity: 1;
    animation: ${slideOut} ${transitionDuration}ms ease-in-out 1;

    & > div:before {
      display: none;
    }

    // Hide the scrollbar in most browsers
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  &.step-exit-active {
    opacity: 0;
    transition: opacity ${transitionDuration / 2}ms ease-in;
  }

  &.step-exit-done {
    opacity: 0;
    transform: translate(${-1 * slideDistance}rem, 0);
  }
`;

const StepContainer = styled.div<{
  isEditMode: boolean;
  displayMode: FlowDisplayMode;
}>`
  ${tw`flex-grow flex-shrink-0 relative z-0 overflow-hidden`}
  width: 38rem;

  ${(props) =>
    !props.isEditMode &&
    css`
      @media (max-width: ${screenLarge}) {
        width: 20rem;
      }

      @media (max-width: ${screenMobile}) {
        ${tw`w-full flex-shrink-0 h-auto`}
        flex-grow: 100;
      }
    `}

  ${(props) =>
    !props.isEditMode &&
    props.displayMode === "modal" &&
    css`
      && {
        ${tw`w-full flex-shrink-0 h-auto`}
        flex-grow: 100;
      }
    `}
`;

const StepAbsolute = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
`;

const StepSizing = styled.div<{
  isEditMode: boolean;
  sidebarPresent?: boolean;
  isForm?: boolean;
  displayMode: FlowDisplayMode;
}>`
  ${tw`py-20 relative`};
  width: ${({ isEditMode, sidebarPresent = true, isForm = false }) =>
    isEditMode && isForm ? "100%" : sidebarPresent ? "38rem" : "auto"};
  padding-left: ${({ isEditMode, isForm = false }) =>
    isForm && isEditMode ? "3rem" : "5rem"};
  margin-right: ${({ sidebarPresent = true }) =>
    sidebarPresent ? "0" : "5rem"};

  ${({ sidebarPresent = true, isEditMode }) =>
    !isEditMode &&
    css`
      @media (max-width: ${screenLarge}) {
        ${tw`px-5 w-auto`}
        ${!sidebarPresent && tw`px-12`}
        margin-right: 0;
      }

      @media (max-width: ${screenMobile}) {
        ${tw`w-full mx-auto p-5`}
        max-width: ${sidebarPresent ? "38rem" : "auto"};

        &:before {
          display: none;
        }
      }
    `}

  ${(props) =>
    !props.isEditMode &&
    props.displayMode === "modal" &&
    css`
      && {
        ${tw`w-full mx-auto p-5`}
        max-width: auto;

        &:before {
          display: none;
        }
      }
    `}

  &:before {
    content: "";
    width: 1px;
    height: calc(100% - 10rem);
    top: 5rem;
    ${tw`absolute left-0`}
    background-color: var(--flow-divider-color);
  }
`;

const FooterFull = styled.div<{ show: boolean; isEditMode: boolean }>`
  ${tw`relative w-full border-t`}
  border-color: transparent; // IE11
  border-color: var(--flow-divider-color);
  bottom: ${(props) => (props.show ? "0" : "-150px")};
  transition: bottom 0.2s;

  ${(props) =>
    !props.isEditMode &&
    css`
      @media (min-height: 900px) and (min-width: 981px) {
        ${tw`border-t-0 border-b`}
        order: -1;
        bottom: auto;
        top: ${props.show ? "0" : "-150px"};
      }
    `}
`;

const TestModeBanner = styled.div`
  ${tw`fixed top-0 left-0 bg-orange-300 font-semibold text-base`}
  z-index: 5;
  width: 150px;
  height: 1.5rem;
  color: white;
  line-height: 1.5rem;
  text-align: center;
  transform: rotate(-45deg) translate(-45px, -10px);
  text-transform: uppercase;

  @media (max-width: ${screenMobile}) {
    left: auto;
    right: 0;
    transform: rotate(45deg) translate(45px, -10px);
  }
`;

export const FlowContent: React.FunctionComponent<FlowContentProps> = ({
  testMode,
  previewMode,
  editorMode,
  currentStepIndex,
  steps,
  currentStep,
  offer,
  nextOrderDate,
  pauseReasons,
  offerGroup,
  swappableProducts,
  loading,
  stepElement,
  nextButtonEnabled,
  isFinalButton,
  isFinalOfferButton,
  submitting,
  pagingDisabled,
  logoUrl,
  companyName,
  showBranding,
  globalCssValue,
  cssValue,
  subscriberSegmentMatches = [],
  flowText,
  status,
  onSetStep,
  onClickNext,
  onAcceptOffer,
  onAcceptGroupOffer,
  onDeclineOffer,
  onDeclineOfferGroup,
  onAbort,
  onUpdateText,
  offerModalIsOpen,
  offerGroupModalIsOpen,
  presentedOfferStepIds,
  displayMode,
  isFreeMode,
  onClickUpgrade = () => {},
  acceptOfferError,
  cancelError,
  eligibilityMessage,
  eligibilityMessageHeader,
}) => {
  const paginationMainRef = useRef<HTMLDivElement>(null);
  const paginationDupeRef = useRef<HTMLDivElement>(null);

  const [stepRefs, setStepRefs] = useState<
    Record<string, React.RefObject<HTMLDivElement>>
  >({});

  useEffect(() => {
    if (!currentStep) {
      return;
    }

    if (!stepRefs[currentStep.id]) {
      setStepRefs({
        ...stepRefs,
        [currentStep.id]: createRef(),
      });
    }
  }, [currentStep, stepRefs, offer, offerGroup]);

  const totalSteps = steps.length;

  useLayoutEffect(() => {
    const els = document.querySelectorAll("style[data-flow-css]");
    for (const el of els) {
      document.head.removeChild(el);
    }

    const globalStyle = document.createElement("style");
    globalStyle.type = "text/css";
    globalStyle.textContent = globalCssValue;
    globalStyle.setAttribute("data-flow-css", "1");
    document.head.appendChild(globalStyle);

    const style = document.createElement("style");
    style.type = "text/css";
    style.textContent = cssValue;
    style.setAttribute("data-flow-css", "1");
    document.head.appendChild(style);
  }, [cssValue, globalCssValue]);

  useLayoutEffect(() => {
    if (paginationMainRef.current && paginationDupeRef.current) {
      paginationDupeRef.current.style.width = `${paginationMainRef.current.clientWidth}px`;
    }
  }, [loading, totalSteps]);

  return (
    <>
      <DefaultStyles
        isEditMode={editorMode}
        modal={false}
        fullScreen={true}
        flowDisplayMode={displayMode}
      />
      <OfferModal
        isOpen={offerModalIsOpen}
        offer={offer}
        nextOrderDate={nextOrderDate}
        pauseReasons={pauseReasons}
        flowText={flowText}
        onAccept={onAcceptOffer}
        onDecline={onDeclineOffer}
        isFinalStep={isFinalOfferButton}
        swappableProducts={swappableProducts}
        error={acceptOfferError}
      />
      <OfferGroupModal
        isOpen={offerGroupModalIsOpen}
        offerGroup={offerGroup}
        pauseReasons={pauseReasons}
        flowText={flowText}
        onAccept={onAcceptGroupOffer}
        onDecline={onDeclineOfferGroup}
        isFinalStep={isFinalButton}
        error={acceptOfferError}
      />
      {!!testMode && !previewMode && <TestModeBanner>Test mode</TestModeBanner>}
      {!editorMode && <CloseXButton onClick={onAbort} />}
      <Layout
        className={classNames(
          "flow-container",
          currentStep
            ? `flow-container--step-${kebabCase(currentStep?.type)}`
            : undefined,
          displayMode === "modal" ? "flow-container--modal" : undefined
        )}
      >
        {loading ? (
          <Spinner className="flow-loading-indicator" />
        ) : !!cancelError ? (
          <div tw="w-full h-full flex flex-col items-center justify-center">
            <FlowErrorAlert error={cancelError} tw="max-w-[400px]" />
            <div>
              <button
                className={classNames("flow-abort-button")}
                tw="text-base! font-semibold hover:underline"
                onClick={onAbort}
              >
                Close
              </button>
            </div>
          </div>
        ) : (
          <>
            <UpsellBanner
              isVisible={isFreeMode && currentStep?.type !== "form"}
              mode={"overlay" as BannerMode}
              onClick={() => onClickUpgrade(true)}
              featureText={((stepType: string) => {
                switch (stepType) {
                  case "acknowledgementGroup":
                    return "Access acknowledgements";

                  case "confirmation":
                    return "Access confirmation steps";

                  case "deflectionRuleGroup":
                    return "Access deflection rules";

                  case "form":
                    return "Access multiple surveys";

                  case "intervention":
                    return "Access interventions";

                  case "offerRuleGroup":
                    return "Access offer rules";

                  default:
                    return "Access all flow features";
                }
              })(currentStep?.type as string)}
            />
            <ContentContainer
              data-vulcan="loaded"
              isEditMode={editorMode}
              displayMode={displayMode}
            >
              <SidebarFull
                className={classNames({
                  "inline-text-context": editorMode,
                  "flow-sidebar-container": true,
                })}
                isEditMode={editorMode}
                show={
                  currentStep?.type !== "intervention" || !!eligibilityMessage
                }
                displayMode={displayMode}
              >
                <SidebarAbsolute
                  isEditMode={editorMode}
                  displayMode={displayMode}
                >
                  <SidebarContent
                    isEditMode={editorMode}
                    displayMode={displayMode}
                  >
                    <div className="flow-sidebar">
                      {logoUrl ? (
                        <img
                          src={logoUrl}
                          alt={companyName}
                          className="flow-sidebar__logo"
                        />
                      ) : (
                        <h1 className="flow-sidebar__company-name">
                          {companyName}
                        </h1>
                      )}
                      {!(
                        currentStep?.type === "confirmation" &&
                        status === "saved"
                      ) && (
                        <>
                          <h2 className="flow-sidebar__headline">
                            <EditableFlowText
                              isEditable={editorMode}
                              translation={flowText.headline}
                              onSave={(value) =>
                                onUpdateText(flow_text_key_enum.headline, value)
                              }
                            />
                          </h2>
                          {currentStep?.type !== "confirmation" && (
                            <p className="flow-sidebar__subheadline">
                              <EditableFlowText
                                isEditable={editorMode}
                                translation={flowText.subheadline}
                                onSave={(value) =>
                                  onUpdateText(
                                    flow_text_key_enum.subheadline,
                                    value
                                  )
                                }
                                isRequired={false}
                                placeholder="Enter text"
                              />
                            </p>
                          )}
                        </>
                      )}

                      {!!subscriberSegmentMatches.length && (
                        <SegmentMatches>
                          {subscriberSegmentMatches.map((segment) => (
                            <SegmentBox key={segment.id}>
                              {segment.name}
                            </SegmentBox>
                          ))}
                        </SegmentMatches>
                      )}
                    </div>
                  </SidebarContent>
                </SidebarAbsolute>
                {showBranding && (
                  <PoweredBy>
                    <a
                      href="https://prosperstack.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={poweredByImg}
                        width={160}
                        height={16}
                        alt="Powered by ProsperStack"
                      />
                    </a>
                  </PoweredBy>
                )}
              </SidebarFull>
              {!!eligibilityMessage ? (
                <StepContainer
                  isEditMode={editorMode}
                  displayMode={displayMode}
                  className="flow-step-container"
                >
                  <div
                    tw="flex flex-row px-[4.5rem] py-[5rem] w-full gap-7"
                    className="flow-eligibility-message"
                  >
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      color="#EF4444"
                      size="xl"
                      transform="grow-6 down-5"
                    />
                    <div tw="flex flex-col">
                      {!!eligibilityMessageHeader && (
                        <div tw="pb-2">
                          <Editor
                            baseFontSize="24px"
                            initialValue={JSON.stringify(
                              eligibilityMessageHeader
                            )}
                            isReadOnly={true}
                          />
                        </div>
                      )}

                      <Editor
                        initialValue={JSON.stringify(eligibilityMessage)}
                        isReadOnly={true}
                      />
                    </div>
                  </div>
                </StepContainer>
              ) : (
                <StepContainer
                  isEditMode={editorMode}
                  displayMode={displayMode}
                  className="flow-step-container"
                >
                  <TransitionGroup component={null}>
                    {currentStep && stepRefs[currentStep.id] && (
                      <StyledCSSTransition
                        key={currentStep.id}
                        timeout={transitionDuration}
                        classNames="step"
                        nodeRef={stepRefs[currentStep.id]}
                        firstStepIn={Object.keys(stepRefs).length === 1}
                      >
                        <StepAbsolute ref={stepRefs[currentStep.id]}>
                          <StepSizing
                            sidebarPresent={currentStep.type !== "intervention"}
                            isEditMode={editorMode}
                            isForm={currentStep.type === "form"}
                            displayMode={displayMode}
                          >
                            <div className="flow-step">{stepElement}</div>
                          </StepSizing>
                        </StepAbsolute>
                      </StyledCSSTransition>
                    )}
                  </TransitionGroup>
                </StepContainer>
              )}
            </ContentContainer>

            <FooterFull
              show={true}
              className={classNames({
                "flow-footer-container": true,
                "inline-text-context": editorMode,
              })}
              isEditMode={editorMode}
            >
              <div className="flow-footer">
                <Tippy
                  duration={600}
                  content="Upgrade to customize flow button text."
                  disabled={!isFreeMode}
                >
                  <div className="flow-footer__content">
                    <div
                      className="flow-footer__pagination flow-footer__pagination-balance"
                      ref={paginationDupeRef}
                    />
                    {currentStep?.type !== "confirmation" &&
                      !eligibilityMessage && (
                        <>
                          <div
                            className="flow-footer__pagination flow-footer__pagination-balance"
                            ref={paginationDupeRef}
                          />
                          <button
                            className={classNames("flow-abort-button", {
                              "flow-abort-button--in-editor": editorMode,
                            })}
                            disabled={submitting || (editorMode && isFreeMode)}
                            onClick={!editorMode ? onAbort : undefined}
                            data-vulcan="abort"
                          >
                            <EditableFlowText
                              isEditable={editorMode}
                              translation={flowText.abort_button}
                              onSave={(value) =>
                                onUpdateText(
                                  flow_text_key_enum.abort_button,
                                  value
                                )
                              }
                              disabled={isFreeMode}
                            />
                          </button>
                        </>
                      )}
                    {!!eligibilityMessage ? (
                      <FlowButton
                        form="survey"
                        buttonType="default"
                        className={classNames(
                          "flow-button--default-in-editor",
                          "flow-button--next"
                        )}
                        onClick={!editorMode ? onAbort : undefined}
                        isLoading={submitting}
                        data-vulcan="close"
                      >
                        <EditableFlowText
                          isEditable={editorMode}
                          translation={flowText.close_button}
                          onSave={(value) =>
                            onUpdateText(flow_text_key_enum.close_button, value)
                          }
                          invert={true}
                          disabled={isFreeMode}
                        />
                      </FlowButton>
                    ) : (
                      <FlowButton
                        form="survey"
                        buttonType={isFinalButton ? "danger" : "default"}
                        className={classNames("flow-button--next", {
                          "flow-button--default-in-editor":
                            !isFinalButton && editorMode,
                          "flow-button--danger-in-editor":
                            isFinalButton && editorMode,
                        })}
                        disabled={!nextButtonEnabled || isFreeMode}
                        onClick={onClickNext}
                        isLoading={submitting}
                        data-vulcan={
                          isFinalButton
                            ? "cancel"
                            : currentStep?.type === "confirmation"
                            ? "close"
                            : "next"
                        }
                      >
                        {isFinalButton ? (
                          <EditableFlowText
                            isEditable={editorMode}
                            translation={flowText.cancel_button}
                            onSave={(value) =>
                              onUpdateText(
                                flow_text_key_enum.cancel_button,
                                value
                              )
                            }
                            invert={true}
                            disabled={isFreeMode}
                          />
                        ) : currentStep?.type === "confirmation" ? (
                          <EditableFlowText
                            isEditable={editorMode}
                            translation={flowText.close_button}
                            onSave={(value) =>
                              onUpdateText(
                                flow_text_key_enum.close_button,
                                value
                              )
                            }
                            invert={true}
                            disabled={isFreeMode}
                          />
                        ) : (
                          <EditableFlowText
                            isEditable={editorMode}
                            translation={flowText.next_button}
                            onSave={(value) =>
                              onUpdateText(
                                flow_text_key_enum.next_button,
                                value
                              )
                            }
                            invert={true}
                            disabled={isFreeMode}
                          />
                        )}
                      </FlowButton>
                    )}

                    {currentStep?.type !== "confirmation" &&
                      !eligibilityMessage && (
                        <div
                          className="flow-footer__pagination"
                          ref={paginationMainRef}
                        >
                          {currentStep &&
                          currentStepIndex > -1 &&
                          currentStepIndex + 1 <= totalSteps ? (
                            <Pagination
                              current={currentStep.id}
                              steps={steps.filter(
                                (s) =>
                                  (s.type === "offerRuleGroup" &&
                                    presentedOfferStepIds.includes(s.id)) ||
                                  (s.type !== "offerRuleGroup" &&
                                    s.type !== "confirmation")
                              )}
                              onSetStep={onSetStep}
                              allDisabled={pagingDisabled}
                            />
                          ) : null}
                        </div>
                      )}
                  </div>
                </Tippy>
              </div>
            </FooterFull>
          </>
        )}
      </Layout>
    </>
  );
};

export default FlowContent;
